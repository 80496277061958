 <template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="80px">
			<el-form-item label-width="0">
				<el-button type="primary" @click="add_view_open('0')">添加主菜单</el-button>
			</el-form-item>
		</el-form>

		<!-- 表格 -->
		<div class="tab_height">
			<el-table 
				:data="list.data" 
				:border="true" 
				:stripe="true" 
				size="small" 
				height="100%" 
				v-loading="list.loading"
				row-key="id" 
				:tree-props="{children: 'children'}"
			>
				<el-table-column label="ID" prop="id" width="80"></el-table-column>

				<el-table-column label="菜单名称/地址">
					<template slot-scope="scope">
						<div v-if="scope.row.fid==0">{{scope.row.name}}</div>
						<div style="margin-left:40px" v-if="scope.row.fid!=0">
							<span>{{scope.row.name}}</span>
							<span>&nbsp;&nbsp;(链接:&nbsp;{{scope.row.url}})</span>
							<span>&nbsp;&nbsp;(权限按钮:&nbsp;</span>
							<span v-if="scope.row.controlled_btns">
								<el-button v-for="(item,index) in scope.row.controlled_btns.split(',')" :key="index" v-if="item" type="text">{{item}}</el-button>)
							</span>
							<span v-else>无)</span>
						</div>
					</template>
				</el-table-column>

				<!-- keep_alive -->
				<el-table-column label="keep_alive" width="150">
					<template slot-scope="scope" v-if="scope.row.fid>0">
						<el-input v-model="scope.row.keep_alive_text" disabled>
							<el-button @click="keep_alive_change(scope.row)" slot="append">{{scope.row.keep_alive_btn}}</el-button>
						</el-input>
					</template>
				</el-table-column>

				<!-- 状态 -->
				<el-table-column label="状态" width="150">
					<template slot-scope="scope">
						<el-input v-model="scope.row.status_text" disabled>
							<el-button @click="status_change(scope.row)" slot="append">{{scope.row.status_btn}}</el-button>
						</el-input>
					</template>
				</el-table-column>
				
				<!-- 排序 -->
				<el-table-column label="排序" width="150">
					<template slot-scope="scope">
						<el-button @click="menu_move(scope.row.id,'up')" size="mini" type="success">
							<span style="font-family: ali_iconfont;">&#xe682;</span>
						</el-button>
						<el-button @click="menu_move(scope.row.id,'down')" size="mini" type="primary">
							<span style="font-family: ali_iconfont;">&#xe668;</span>
						</el-button>
					</template>
				</el-table-column>
				
				<!-- 操作行 -->
				<el-table-column label="操作" width="310">
					<template slot-scope="scope">
						<el-button @click="edit_view_open(scope.row)" size="mini" type="primary" icon="el-icon-edit">修改</el-button>
						<el-button @click="del(scope.row)" size="mini" type="danger" icon="el-icon-circle-close">删除</el-button>
						<el-button @click="add_view_open(scope.row.id)" v-if="scope.row.fid==0" size="mini" type="success" icon="el-icon-circle-check">添加子栏目</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 修改弹出层 -->
		<edit 
			:fname="edit.fname" 
			:is_show="edit.is_show" 
			:data="edit.data" 
			@edited="edit_view_close" 
		></edit>

		<!-- 添加弹出层 -->
		<add 
			:fid="add.fid" 
			:fname="add.fname" 
			:is_show="add.is_show" 
			@added="add_view_close"
		></add>

	</div>
</template>

<script>
	import add from './add.vue'
	import edit from './edit.vue'
	export default {
		components:{
			add,
			edit
		},
		data() {
			return {

				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//缓存数据
				menu_buf:{},

				//添加弹窗
				add:{
					fid:'',
					fname:'',
					is_show:0,
				},

				//修改弹窗
				edit:{
					data:{},
					fname:'',
					is_show:0,
				}
			}
		},
		created() {
			
			//读取用户组数据
			this.get_page_data()
		},
		methods: {

			//向上移动菜单
			menu_move(item_id,type){
				let this_menu=this.menu_buf[item_id]
				switch(type){
					case 'up':var another_menu=this.get_another_menu(this_menu,'last');break;
					case 'down':var another_menu=this.get_another_menu(this_menu,'next');break;
				}
				
				if(!another_menu){
					this.$my.other.msg({
						type:'success',
						str:'已在最顶端'
					});
					return;
				}
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_edit_by_admin',
						edit_data:[{
								id:this_menu.id,
								weight:another_menu.weight
							},{
								id:another_menu.id,
								weight:this_menu.weight
							}
						]
					},
					callback:(data)=>{
						this.$my.other.msg({
							type:'success',
							str:'操作成功'
						});
						this.get_page_data()
					}
				})
			},
			get_another_menu(this_menu,type){
				let menu_buf=false
				switch(type){
					case 'last':var weight_buf=0;break;
					case 'next':var weight_buf=1000000000;break;
				}
				for(let i in this.menu_buf){
					let item=this.menu_buf[i]
					
					this_menu.weight=parseInt(this_menu.weight)
					item.weight=parseInt(item.weight)
					
					if(type=='last'&&this_menu.fid==item.fid){
						if(this_menu.weight>item.weight&&weight_buf<item.weight){
							weight_buf=item.weight
							menu_buf=item
						}
					}else if(type=='next'&&this_menu.fid==item.fid){
						if(this_menu.weight<item.weight&&weight_buf>item.weight){
							weight_buf=item.weight
							menu_buf=item
						}
					}
				}
				return menu_buf
			},

			//keep_alive操作
			keep_alive_change(item){

				//
				switch(item.keep_alive){
					case '1':
						var confirm_text="点击'确定'关闭keep_alive";
						var new_keep_alive=2;
						break;
					case '2':
						var confirm_text="点击'确定'启用keep_alive";
						var new_keep_alive=1;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:confirm_text,
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'menu_edit_by_admin',
								id:item.id,
								keep_alive:new_keep_alive
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							}
						})
					}
				})
			},

			//状态变更
			status_change(item){

				//
				switch(item.status){
					case '1':
						var confirm_text="点击'确定'隐藏此菜单";
						var new_statue=2;
						break;
					case '2':
						var confirm_text="点击'确定'显示此菜单";
						var new_statue=1;
						break;
				}

				//询问
				this.$my.other.confirm({
					content:confirm_text,
					confirm:()=>{
						
						//调接口
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'menu_edit_by_admin',
								id:item.id,
								status:new_statue
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							}
						})
					}
				})
			},

			//删除
			del(item){

				//询问
				this.$my.other.confirm({
					content:"是否删除此项",
					confirm:()=>{
						this.$my.net.req({
							data:{
								mod:'admin',
								ctr:'menu_del_by_admin',
								menu_id:item.id,
							},
							callback:(data)=>{
								this.$my.other.msg({
									type:'success',
									str:'操作成功'
								});
								this.get_page_data()
							},
						});
					}
				});
			},

			//添加页面
			add_view_open(fid){
				if(fid!=0){
					this.add.fname=this.menu_buf[fid].name;
				}else this.add.fname='无'
				this.add.fid=fid;
				this.add.is_show++
			},
			add_view_close(){
				this.add.is_show=0
				this.get_page_data()
			},

			//打开用户组修改页面
			edit_view_open(item){
				if(item.fid!=0){
					this.edit.fname=this.menu_buf[item.fid].name;
				}else this.edit.fname='无'
				this.edit.data=item
				this.edit.is_show++
			},
			edit_view_close(item){
				this.edit.is_show=0
				this.get_page_data()
			},

			//读取用户组数据
			get_page_data(){

				//加载中...
				if(this.list.loading){
					return;
				}

				//置为加载中
				this.list.loading=true;

				//读取数据
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_list_by_admin',
					},
					callback:(data)=>{
						
						//加载完毕
						this.list.loading=false
						
						//格式化主菜单
						let menu_buf={}
						let menu_main=[];
						let menu_children={};
						for(var item of data.list){

							//状态
							switch(item.status){
								case '1':
									item.status_text='正常';
									item.status_btn='隐藏';
									break;
								case '2':
									item.status_text='隐藏';
									item.status_btn='显示';
									break;
							}

							//状态
							switch(item.keep_alive){
								case '1':
									item.keep_alive_text='启用';
									item.keep_alive_btn='关闭';
									break;
								case '2':
									item.keep_alive_text='关闭';
									item.keep_alive_btn='启用';
									break;
							}

							menu_buf[item.id]={...item};

							if(item.fid==0){
								menu_main.push(item)
							}else{
								if(!menu_children[item.fid])menu_children[item.fid]=[];
								menu_children[item.fid].push(item)
							}
						}
						
						//格式化子菜单
						for(var i in menu_main){
							menu_main[i].children=menu_children[menu_main[i].id]
						}

						//置入数据
						this.menu_buf=menu_buf
						this.list.data=menu_main
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>

	//修改/添加表单的额外权限菜单部分
	.menu_list{
		background-color:#eee;
		height: 500px;
		overflow: auto;
		padding: 0 20px;
		margin-top: 10px;
		width: 90%;
		.children_list{
			margin-left: 20px;
		}
	}

	//其他
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:calc(100% - 60px);
	}
	.el_form_item{
		width: 16.667%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
</style>