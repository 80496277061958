 <template>
	<el-dialog title="添加菜单" top="1vh" width="60%" :visible.sync="is_show_in_page">
		
		<!-- 表单 -->
		<el-form class="form" label-width="80px">
			<div class="big_tit" style="margin:0">基本数据</div>
			<div class="tab1_inner">
				<el-form-item class="el_form_item" label="上级菜单" style="width:30%">
					<el-input class="el_inner_width" v-model="fname" disabled></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="菜单名称" style="width:30%">
					<el-input class="el_inner_width" v-model="form.name" placeholder="必填" clearable></el-input>
				</el-form-item>
				<el-form-item class="el_form_item" label="链接地址" style="width:40%">
					<el-input class="el_inner_width" v-model="form.url" placeholder="必填" clearable></el-input>
				</el-form-item>
			</div>


			<div class="big_tit">
				<div>权限按钮</div>
				<el-button @click="controlled_btns_row_add" type="primary" size="mini" style="position:absolute;left:100px;top:8px">增加一行</el-button>
			</div>
			<div class="tab_height">
				<el-table :data="controlled_btns" :border="true" :stripe="true" size="small" height="100%">
					<el-table-column label="按钮名称">
						<template slot-scope="scope">
							<el-input class="el_inner_width" v-model="scope.row.name" placeholder="必填" clearable></el-input>
						</template>
					</el-table-column>

					<!-- 操作行 -->
					<el-table-column label="操作" width="110">
						<template slot-scope="scope">
							<el-button @click="controlled_btns_row_del(scope.$index)" size="mini" type="text">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
		</el-form>

		<div class="bottom_btns">
			<el-button type="primary" @click="sub">确定添加</el-button>
			<el-button type="danger" @click="clear">清空数据</el-button>
		</div>

	</el-dialog>
</template>

<script>
	export default {
		props:{
			fid:String,
			fname:String,
			is_show:Number,
		},
		data() {
			return {
				
				//是否显示
				is_show_in_page:false,

				//表单
				form:{
					name:'',
					url:'',
				},

				//权限按钮
				controlled_btns:[]
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){

					//打开弹出层
					this.is_show_in_page=true;
					
					//初始化
					this.init();

				}else{

					//关闭弹出层
					this.is_show_in_page=false;
				}
			}
		},
		methods: {

			//权限按钮添加/删除
			controlled_btns_row_add(index=-1,data={}){
				if(index==-1){
					index=this.controlled_btns.length
				}
				this.controlled_btns.splice(index,0,{
					name:data.name?data.name:'',
				});
			},
			controlled_btns_row_del(index){
				this.controlled_btns.splice(index,1)
			},

			//提交
			sub(){

				if(!this.form.name.trim()){
					this.$my.other.msg({
						type:'warning',
						str:"菜单名称为空,请输入"
					});
					return;
				}

				if(!this.form.url.trim() && this.fid!=0){
					this.$my.other.msg({
						type:'warning',
						str:"页面地址为空,请输入"
					});
					return;
				}

				let controlled_btns=[];
				for(var item of this.controlled_btns){
					if(item.name.trim())controlled_btns.push(item.name.trim());
				}

				//调用接口
				this.$my.net.req({
					data:{
						mod:'admin',
						ctr:'menu_add_by_admin',
						fid:this.fid,
						controlled_btns:controlled_btns,
						...this.form
					},
					callback:(data)=>{

						//报告结果
						this.$my.other.msg({
							type:'success',
							str:"添加成功"
						});

						//关闭弹出层
						this.is_show_in_page=false;

						//通知
						this.$emit("added");
					}
				});
			},

			//初始化
			init(){
				
				//清空表单数据
				this.form.name="";
				this.form.url="";

				//清空组合内容
				this.controlled_btns=[];
				this.controlled_btns_row_add();
			},

			//清空本页数据
			clear(){

				this.$my.other.confirm({
					content:"点击确定清空本页数据",
					confirm:()=>{

						//初始化
						this.init();
					}
				});
			},
		}
	}
</script>
<style lang="scss" scoped>

	.el_form_item{
		margin: 0;
	}
	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
		position:relative;
	}
	.tab1_inner{
		padding: 15px 15px 0 15px;
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:94%;
	}
	.el_inner_div{
		cursor: pointer;
	}

	.bottom_btns{
		text-align: center;
		position: absolute;
		bottom: 0px;
		width: calc(100% - 60px);
		padding: 15px 0;
		background-color: #fff;
		border-top: 1px solid #ccc;
	}

	.form{
		height: calc(100% - 100px);
		overflow-y:auto;
		padding-bottom:75px;
		margin-top:-20px;
	}

	.tab_height{
		height:300px;
	}

	.page{
		position:relative;
		background-color: #fff;
		border-radius: 10px;
		height: 100%;
		width: 1040px;
		overflow: auto;
		margin: auto;
	}

</style>